import React from "react";
import Styles from "./List.module.css";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(isSameOrAfter);
dayjs.extend(relativeTime);

const List = ({ items, handleDelete }: any) => {
  const deleteItem = (id: any) => {
    return window.confirm("Are you sure?") && handleDelete(id);
  };

  return (
    <ol
      data-placeholder="No reminders yet? Add one..."
      data-testid="list"
      className={Styles.List}
    >
      {items &&
        items
          .sort(
            (a: any, b: any) =>
              new Date(a.ExpiresOn).getTime() - new Date(b.ExpiresOn).getTime()
          )
          .map((item: any) => (
            <li key={item.id} className={Styles.Item}>
              <p className={Styles.Title}>{item.Title}</p>
              <time dateTime={dayjs(item.ExpiresOn).format("YYYY-MM-DD")}>
                <span className="hidden">Status:</span>
                {dayjs().isSameOrAfter(dayjs(item.ExpiresOn))
                  ? "Expired"
                  : `Expires ${dayjs(item.ExpiresOn).fromNow()}`}
              </time>
              <button
                className={Styles.Button}
                onClick={(_) => deleteItem(item.id)}
              >
                Delete
              </button>
            </li>
          ))}
    </ol>
  );
};

export default List;
