import React, { useState, useEffect } from "react";
import localforage from "localforage";

type contextProps = {
  items: any[];
  addData: any;
  deleteItem: any;
};

const ReminderContext = React.createContext<Partial<contextProps>>({});

const DB = localforage.createInstance({
  name: process.env.REACT_APP_DB_NAME || "noxpiry",
});

const ReminderProvider = (props: any) => {
  const [items, setItems] = useState([]);

  const addData = (data: any) => {
    const id = `${Date.now()}`;
    DB.setItem(id, {
      id,
      ...data,
    }).then((data) => setItems(items.concat(data)));
  };

  const deleteItem = (id: any) => {
    DB.removeItem(id).then(() =>
      setItems(items.filter((item: any) => item.id !== id))
    );
  };

  useEffect(() => {
    // Fetch data on load
    const fetchData = async () => {
      const keys = await DB.keys();
      if (keys) {
        // Get data
        const data: any = await Promise.all(keys.map((key) => DB.getItem(key)));
        setItems(data);
      }
    };

    fetchData();
  }, []);

  return (
    <ReminderContext.Provider value={{ addData, deleteItem, items }}>
      {props.children}
    </ReminderContext.Provider>
  );
};

export { ReminderContext, ReminderProvider };
