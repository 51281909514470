import React from "react";
import Styles from "./Form.module.css";
import { Formik } from "formik";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

const initialData = {
  Title: "",
  ExpiresOn: dayjs().add(2, "day").format("YYYY-MM-DD"),
  RemindOn: dayjs().add(1, "day").format("YYYY-MM-DD"),
};

const Form = ({ sendData }: any) => {
  return (
    <Formik
      initialValues={initialData}
      validate={(values) => {
        let errors: any = {};
        if (values.Title.length < 2) {
          errors.Title = "Title should be atleast 2 characters";
        }

        if (!values.ExpiresOn) {
          errors.ExpiresOn = "Expiry date is required";
        }

        if (!values.RemindOn) {
          errors.RemindOn = "Reminder date is required";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        sendData(values);
        setSubmitting(false);
        resetForm();
      }}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        resetForm,
      }) => (
        <form
          onSubmit={handleSubmit}
          data-testid="form"
          className={Styles.Form}
        >
          <label className={Styles.Info}>
            <span>Item</span>
            <input
              value={values.Title}
              onChange={handleChange}
              onBlur={handleBlur}
              name="Title"
              placeholder="Item information"
              className="Input"
            />
            {errors.Title && touched.Title && (
              <span className={Styles.Errors}>{errors.Title}</span>
            )}
          </label>
          <label className={Styles.ExpiresOn}>
            <span>Expires on</span>
            <input
              value={dayjs(values.ExpiresOn).format("YYYY-MM-DD")}
              min={dayjs().add(2, "day").format("YYYY-MM-DD")}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              name="ExpiresOn"
              placeholder="Expires on"
              className="Input"
            />
            {errors.ExpiresOn && touched.ExpiresOn && (
              <span className={Styles.Errors}>{errors.ExpiresOn}</span>
            )}
          </label>
          <label className={Styles.RemindOn}>
            <span>Remind on</span>
            <input
              value={dayjs(values.RemindOn).format("YYYY-MM-DD")}
              min={dayjs().add(1, "day").format("YYYY-MM-DD")}
              max={dayjs(values.ExpiresOn)
                .subtract(1, "day")
                .format("YYYY-MM-DD")}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              name="RemindOn"
              placeholder="Remind on"
              className="Input"
            />
            {errors.RemindOn && touched.RemindOn && (
              <span className={Styles.Errors}>{errors.RemindOn}</span>
            )}
          </label>

          <div className={Styles.Controls}>
            <button
              type="submit"
              disabled={!isValid || !dirty}
              className={Styles.Save}
            >
              Create reminder
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Form;
