import React, { useState, useContext } from "react";
import Styles from "./App.module.css";
import Form from "./components/Form";
import List from "./components/List";
import { ReminderContext } from "./contexts/reminderContext";

function App() {
  const [nightmode, setNightmode] = useState(false);
  const { items, deleteItem, addData } = useContext(ReminderContext);

  return (
    <div
      data-testid="App"
      className={Styles.Wrapper}
      data-nightmode={nightmode}
    >
      <header className={Styles.Header}>
        <h1 className={Styles.Title} data-subtitle="Keep track of expiry dates">
          NoXpiry
        </h1>
        <button className="Button" onClick={(_) => setNightmode(!nightmode)}>
          Toggle nightmode
        </button>
      </header>
      <div className={Styles.Content}>
        <Form sendData={addData} />
        <List items={items} handleDelete={deleteItem} />
      </div>
    </div>
  );
}

export default App;
